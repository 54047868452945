<template>
  <div class="balance">
    <div class="sub-wrap">
      <div class="container">
        <div class="header-wrap cf">
          <div class="header-left cf">
            <img src="../../../assets/icons/guthaben.svg" />
            <h2>{{ $t("global.balance") }}</h2>
          </div>
        </div>

        <div class="content-wrap">
          <header class="content-header">
            <h3>{{ $t("balance.addPayment") }}</h3>
          </header>
          <div class="content-body">
            <ul class="nav nav-tabs cf">
              <li @click="method = 'sepa'" :class="check_if_active('sepa')">
                <a>{{ $t("balance.sepa") }}</a>
              </li>
              <li @click="method = 'card'" :class="check_if_active('card')">
                <a>{{ $t("balance.creditcard") }}</a>
              </li>
            </ul>

            <div v-show="method == 'sepa'" id="payment-sepa" class="payment-sepa payment-method-wrap">
              <h3>{{ $t("balance.sepaData") }}</h3>

              <form action="/charge" method="post" id="sepa-form">
                <div class="row row-gutter-20">
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="name">{{ $t("global.name") }}</label>
                      <input id="sepa-name" v-model="user.name" name="name" placeholder="Max Mustermann" class="form-input" required />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="email">E-Mail</label>
                      <input id="sepa-email" v-model="user.email" name="email" type="email" class="form-input" required />
                    </div>
                  </div>
                </div>

                <div class="form-wrap">
                  <label for="iban-element">IBAN</label>
                  <div id="iban-element"></div>
                </div>
                <div id="bank-name"></div>

                <button id="sepa-button" class="button button-ci button-100" :data-secret="intent">{{ $t("balance.sepaSave") }}</button>

                <div id="error-message" role="alert"></div>

                <div id="mandate-acceptance" class="disclaimer">{{ $t("balance.sepaDisclaimer") }}</div>
              </form>
            </div>

            <div v-show="method == 'card'" id="payment-card" class="payment-card payment-method-wrap">
              <h3>{{ $t("balance.cardAdd") }}</h3>

              <form id="payment-form">
                <div class="row row-gutter-20">
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="name">{{ $t("global.name") }}</label>
                      <input id="card-name" v-model="user.name" name="name" placeholder="Max Mustermann" class="form-input" required />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="email">E-Mail</label>
                      <input id="card-email" v-model="user.email" name="email" type="email" class="form-input" required />
                    </div>
                  </div>
                </div>

                <div class="form-wrap">
                  <label for="card-element">{{ $t("balance.cardData") }}</label>
                  <div id="card-element"></div>
                </div>

                <!-- We'll put the error messages in this element -->
                <div id="card-errors" role="alert"></div>

                <button id="submit" class="button button-ci button-100">{{ $t("balance.cardSave") }}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "balance",
  data() {
    return {
      user: {},
      intent: "",
      stripe: "",
      method: "sepa",
    };
  },
  methods: {
    get_user() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/user", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.user = response.data.user;
          if (response.data.user.stripe_id) {
            this.create_setup_intent();
          } else {
            axios
              .post(process.env.VUE_APP_BASE_API + "/stripe/customers", {}, { headers: { Authorization: this.$store.getters.getToken } })
              .then((response) => {
                console.log(response);
                this.get_user();
              });
          }
        });
    },
    create_setup_intent() {
      axios
        .post(process.env.VUE_APP_BASE_API + "/stripe/setup_intents", {}, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          console.log(response);
          this.intent = response.data;
          this.stripe_sepa_action();
          this.stripe_card_action();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    stripe_sepa_action() {
      var elements = this.stripe.elements();

      var style = {
        base: {
          color: "#32325d",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
          ":-webkit-autofill": {
            color: "#32325d",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
          ":-webkit-autofill": {
            color: "#fa755a",
          },
        },
      };

      var options = {
        style: style,
        supportedCountries: ["SEPA"],
        placeholderCountry: "DE",
      };

      var iban = elements.create("iban", options);

      iban.mount("#iban-element");

      var form = document.getElementById("sepa-form");
      var accountholderName = document.getElementById("sepa-name");
      var email = document.getElementById("sepa-email");
      //var submitButton = document.getElementById('sepa-button');
      var clientSecret = this.intent;
      var that = this;

      iban.on("change", function (event) {
        var displayError = document.getElementById("error-message");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });

      form.addEventListener("submit", function (event) {
        event.preventDefault();

        that.stripe
          .confirmSepaDebitSetup(clientSecret, {
            payment_method: {
              sepa_debit: iban,
              billing_details: {
                name: accountholderName.value,
                email: email.value,
              },
            },
          })
          .then(function (result) {
            console.log(result);
            if (result.error) {
              console.log(result.error);
            } else {
              console.log(result);

              axios
                .post(
                  process.env.VUE_APP_BASE_API + "/user/payment_methods",
                  {
                    stripe_id: result.setupIntent.payment_method,
                    method_type: "sepa",
                  },
                  { headers: { Authorization: that.$store.getters.getToken } }
                )
                .then((response) => {
                  console.log(response);
                  that.$notify({
                    group: "alert",
                    type: "success",
                    title: that.$t("balance.paymentAdded"),
                  });
                  that.$router.push("/balance");
                })
                .catch((error) => {
                  this.errors = error.response.data.errors;
                });
            }
          });
      });
    },
    stripe_card_action() {
      var elements = this.stripe.elements();
      var style = {
        base: {
          color: "#444",
          fontSize: "16px",
        },
      };

      var card = elements.create("card", { style: style });
      card.mount("#card-element");

      card.on("change", ({ error }) => {
        const displayError = document.getElementById("card-errors");
        if (error) {
          displayError.textContent = error.message;
        } else {
          displayError.textContent = "";
        }
      });

      var form = document.getElementById("payment-form");
      var clientSecret = this.intent;
      var that = this;

      form.addEventListener("submit", function (ev) {
        ev.preventDefault();
        that.stripe
          .confirmCardSetup(clientSecret, {
            payment_method: {
              card: card,
              billing_details: {
                name: that.user.name,
                email: that.user.email,
              },
            },
          })
          .then(function (result) {
            if (result.error) {
              console.log(result.error.message);

              that.$notify({
                group: "alert",
                type: "error",
                text: result.error.message,
              });
            } else {
              axios
                .post(
                  process.env.VUE_APP_BASE_API + "/user/payment_methods",
                  {
                    stripe_id: result.setupIntent.payment_method,
                    method_type: "card",
                  },
                  { headers: { Authorization: that.$store.getters.getToken } }
                )
                .then((response) => {
                  console.log(response);
                  that.$notify({
                    group: "alert",
                    type: "success",
                    title: that.$t("balance.paymentAdded"),
                  });
                  that.$router.push("/balance");
                })
                .catch((error) => {
                  that.$notify({
                    group: "alert",
                    type: "error",
                    text: error.response,
                  });
                });
            }
          });
      });
    },
    init_stripe() {
      const Stripe = window.Stripe;
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
    },
    check_if_active(method) {
      if (method == this.method) {
        return "active";
      }
    },
  },
  mounted() {
    this.get_user();
    this.init_stripe();
  },
};
</script>

<style lang="scss">
.nav-tabs {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #f1f1f1;

  li {
    float: left;
    list-style-type: none;
    position: relative;
    bottom: -1px;

    a {
      display: block;
      padding: 15px 20px;
      font-weight: 600;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  li.active {
    a {
      background: #f9f9f9;
      border: 1px solid #f1f1f1;
    }
  }
}

.payment-method-wrap {
  background: #f9f9f9;
  border: 1px solid #f1f1f1;
  border-top: 0px;
  padding: 30px;

  h3 {
    font-weight: 600;
    margin-bottom: 20px !important;
  }

  .disclaimer {
    font-size: 12px;
    letter-spacing: 0;
    margin-top: 20px;
  }

  #iban-element {
    background: #fff;
    padding: 14px;
    -webkit-box-shadow: inset 0 0 3px 0 rgba(215, 227, 230, 0.4);
    box-shadow: inset 0 0 3px 0 rgba(215, 227, 230, 0.4);
    border-radius: 4px;
    border: 1px solid #d7e3e6;
  }
}

#card-errors {
  margin-bottom: 20px;
}
</style>
